import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';

const StyledContactSection = styled.section`
	// max-width: 600px;
	margin: 0 auto 100px;
	text-align: center;

	@media (max-width: 768px) {
		margin: 0 auto 50px;
	}

	.overline {
		display: block;
		margin-bottom: 20px;
		color: var(--accent);
		font-family: var(--font-mono);
		font-size: var(--fz-md);
		font-weight: 400;

		&:before {
			bottom: 0;
			font-size: var(--fz-sm);
		}

		&:after {
			display: none;
		}
	}

	.title {
		font-size: clamp(40px, 5vw, 60px);
	}

	p {
		max-width: 600px;
		margin: 0 auto;
	}

	.email-link {
		${({ theme }) => theme.mixins.bigButton};
		margin-top: 50px;
	}
`;

const Contact = () => {
	const revealContainer = useRef(null);
	useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

	return (
		<StyledContactSection id="contact" ref={revealContainer}>
			<h2 className="numbered-heading">What’s Next?</h2>

			<h2 className="title">Get In Touch</h2>

			<p>
				My inbox is always open. Whether you have a question or just want to say hi, I'll try my best to get
				back to you!
			</p>

			<a className="email-link" href={`mailto:${email}`}>
				Say Hello
			</a>
		</StyledContactSection>
	);
};

export default Contact;
