import React from 'react';

const IconLogo = () => (
	<svg
		id="logo"
		role="img"
		width="305"
		height="145"
		viewBox="0 0 305 145"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Mahmudul Hasan Sreejon</title>

		<path
			d="M25.8584 78.8164L23.8809 32.9375H25.2256L45.5547 92.1846H59.1602L79.4893 32.9375H80.834L78.8564 78.8164V119H95.1514V4.85645H74.9805L52.9902 72.4092H51.8037L29.6553 4.85645H9.56348V119H25.8584V78.8164Z"
			fill="#DC3545"
		/>
		<path
			d="M192.13 119V4.85645H173.304V52.5547H131.696V4.85645H112.87V119H131.696V69.2451H173.304V119H192.13Z"
			fill="#DC3545"
		/>
		<path
			d="M211.431 87.6758C212.38 108.559 228.279 121.294 253.275 121.294C278.825 121.294 294.646 107.926 294.646 86.252C294.646 68.9287 285.153 59.1201 263.084 53.9785L252.089 51.3682C238.721 48.2832 233.184 43.2998 233.184 34.5195C233.184 24.6318 240.856 18.6992 253.513 18.6992C265.536 18.6992 273.367 24.8691 274.396 35.3105H292.589C291.877 15.5352 276.294 2.5625 253.354 2.5625C229.387 2.5625 214.041 15.6934 214.041 36.1807C214.041 52.9502 224.008 63.4707 244.891 68.4541L255.886 71.0645C269.966 74.3867 275.661 79.291 275.661 87.9131C275.661 98.4336 267.039 105.157 253.592 105.157C239.67 105.157 230.811 98.6709 229.703 87.6758H211.431Z"
			fill="#DC3545"
		/>
		<path d="M2.28613 134.82H302.714V142.73H2.28613V134.82Z" fill="#DC3545" />
		<path
			d="M25.8584 78.8164L23.8809 32.9375H25.2256L45.5547 92.1846H59.1602L79.4893 32.9375H80.834L78.8564 78.8164V119H95.1514V4.85645H74.9805L52.9902 72.4092H51.8037L29.6553 4.85645H9.56348V119H25.8584V78.8164Z"
			stroke="#DC3545"
			strokeWidth="4"
		/>
		<path
			d="M192.13 119V4.85645H173.304V52.5547H131.696V4.85645H112.87V119H131.696V69.2451H173.304V119H192.13Z"
			stroke="#DC3545"
			strokeWidth="4"
		/>
		<path
			d="M211.431 87.6758C212.38 108.559 228.279 121.294 253.275 121.294C278.825 121.294 294.646 107.926 294.646 86.252C294.646 68.9287 285.153 59.1201 263.084 53.9785L252.089 51.3682C238.721 48.2832 233.184 43.2998 233.184 34.5195C233.184 24.6318 240.856 18.6992 253.513 18.6992C265.536 18.6992 273.367 24.8691 274.396 35.3105H292.589C291.877 15.5352 276.294 2.5625 253.354 2.5625C229.387 2.5625 214.041 15.6934 214.041 36.1807C214.041 52.9502 224.008 63.4707 244.891 68.4541L255.886 71.0645C269.966 74.3867 275.661 79.291 275.661 87.9131C275.661 98.4336 267.039 105.157 253.592 105.157C239.67 105.157 230.811 98.6709 229.703 87.6758H211.431Z"
			stroke="#DC3545"
			strokeWidth="4"
		/>
		<path d="M2.28613 134.82H302.714V142.73H2.28613V134.82Z" stroke="#DC3545" strokeWidth="4" />
	</svg>
);

export default IconLogo;
